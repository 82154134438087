import { z } from "zod";
import { GridPaginationOptions } from "@ecp-redux/dto/article.type";
import {
  BannersPositions,
  CountOfTemplatesInShake,
  HoverEffects,
  ProductHoverEffects,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  OutlineOptions,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  MainButtonContentSettingScheme,
  createLinkStyleSettingScheme,
  createTypoStyleSettingScheme,
} from "../../settingsSchemes/settingsSchemes.utils";
import { ProductButtonActions } from "../../shared/components/domain/Product/ProductModule/ProductModule.types";

export const IBoxSearchResultsMessagesScheme = z.object({
  reviewsText: themeScheme.MessageScheme.default("Reviews"),
  paginationFrom: themeScheme.MessageScheme.default("from"),
  no_photo_text: themeScheme.MessageScheme.default("No photo"),
  unknown_product_text: themeScheme.MessageScheme.default("Unknown Product"),
  descNameSortText: themeScheme.MessageScheme.default("name Z-A"),
  ascNameSortText: themeScheme.MessageScheme.default("name A-Z"),
  descPriceSortText: themeScheme.MessageScheme.default("lower price"),
  ascPriceSortText: themeScheme.MessageScheme.default("higher price"),
  defaultSortText: themeScheme.MessageScheme.default("default"),
  add_to_cart_error_message: themeScheme.MessageScheme.default(
    "Brak możliwości dodania produktu do koszyka"
  ),
  add_to_cart_success_message: themeScheme.MessageScheme.default(
    "Produkt dodany do koszyka"
  ),
  product_error_limit_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono dozwolony limit produktów"
  ),
  product_not_available_error: themeScheme.MessageScheme.default(
    "Produkt niedostępny"
  ),
  product_error_stock_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono ilość produktu dostępną na stock"
  ),
  cart_quantity_limit_exceeded_warning: themeScheme.MessageScheme.default(
    "Przekroczono limit koszykowy"
  ),
  srp_mobile_show_result_button_label:
    themeScheme.MessageScheme.default("Pokaż wyniki"),

  add_to_wishlist_success_text: themeScheme.MessageScheme.default(
    "Dodałeś produkt do schowka"
  ),
  delete_from_wishlist_success_text: themeScheme.MessageScheme.default(
    "Usunąłeś produkt ze schowka"
  ),
  wishlist_error_text: themeScheme.MessageScheme.default(
    "Brak możliwości zmiany w schowku"
  ),
  wishlist_not_logged_error: themeScheme.MessageScheme.default(
    "Zaloguj się przed dodaniem produktu do schowka"
  ),
});

export const IBoxSearchResultsSettingsScheme = z.object({
  optionsAlign: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.RIGHT)
    .catch(AlignmentHorizontalOption.RIGHT),
  add_to_cart_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$2").catch("snackbar$$2"),
  add_to_cart_success_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  srp_mobile_sorting_parameter_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  srp_mobile_show_result_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  srp_mobile_right_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  wishlist_success_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  wishlist_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$2").catch("snackbar$$2"),
  attribiute_in_row:
    themeScheme.SettingBooleanScheme.default("false").catch("false"),
  attribute_row_1: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_2: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_3: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_4: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_5: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_6: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_7: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_8: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_9: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_10: themeScheme.SettingAttributesScheme.default("").catch(""),
});

const productSRPModuleElement = [
  "sku",
  "productName",
  "actualPrice",
  "oldPrice",
  "netPrice",
  "rating",
  "delivery",
  "button",
] as const;

export const productElementInModule = [
  "sku",
  "productName",
  "actualPrice",
  "oldPrice",
  "netPrice",
  "rating",
  "delivery",
  "button",
];

export const BoxSearchResultsDisplaySettingsScheme = z.object({
  grid: z
    .object({
      spaceBetweenRows: z.number().default(24).catch(24),
      spaceBetweenProducts: z.number().default(20).catch(20),
    })
    .default({ spaceBetweenRows: 24, spaceBetweenProducts: 20 })
    .catch({
      spaceBetweenRows: 24,
      spaceBetweenProducts: 20,
    }),
  module: z.object({
    alignment: z
      .nativeEnum(AlignmentHorizontalOption)
      .default(AlignmentHorizontalOption.LEFT)
      .catch(AlignmentHorizontalOption.LEFT),
    padding: themeScheme.SpacingScheme.default({
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    }).catch({
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    }),
    spaceBetweenPhotoAndContent: z.number().default(20).catch(20),
    spaceBetweenTextElements: z.number().default(8).catch(8),
    background: themeScheme.ColorReadoutValueScheme.removeCatch()
      .removeDefault()
      .default({ id: "color$$7", custom: "#000000" })
      .catch({ id: "color$$7", custom: "#000000" }),
    border: z.object({
      style: z
        .nativeEnum(OutlineOptions)
        .default(OutlineOptions.NONE)
        .catch(OutlineOptions.NONE),
      width: z.number().default(10).catch(10),
      color: themeScheme.ColorReadoutValueScheme.removeCatch()
        .removeDefault()
        .default({
          id: "color$$11",
          custom: "#000000",
        })
        .catch({ id: "color$$11", custom: "#000000" }),
    }),
    spaceBetweenSlides: z.number().default(0).catch(0),
  }),
  pagination: z
    .nativeEnum(GridPaginationOptions)
    .default(GridPaginationOptions.DEFAULT)
    .catch(GridPaginationOptions.DEFAULT),
  orderInModule: z
    .array(z.union([z.enum(productSRPModuleElement), z.string()]))
    .default(productElementInModule)
    .catch(productElementInModule),
  marketingCampaign: z
    .object({
      turnOn: z.boolean().default(false).catch(false),
      positionOption: z
        .nativeEnum(BannersPositions)
        .default(BannersPositions.SHAKE)
        .catch(BannersPositions.SHAKE),
      fixPositionOrder: z.array(z.any()).default([]).catch([]),
      orderOption: z
        .nativeEnum(BannersPositions)
        .default(BannersPositions.FIX)
        .catch(BannersPositions.FIX),
      countOfTemplatesInShake: z
        .nativeEnum(CountOfTemplatesInShake)
        .default(CountOfTemplatesInShake.ONE)
        .catch(CountOfTemplatesInShake.ONE),
    })
    .default({
      turnOn: false,
      positionOption: BannersPositions.SHAKE,
      fixPositionOrder: [],
      orderOption: BannersPositions.FIX,
      countOfTemplatesInShake: CountOfTemplatesInShake.ONE,
    })
    .catch({
      turnOn: false,
      positionOption: BannersPositions.SHAKE,
      fixPositionOrder: [],
      orderOption: BannersPositions.FIX,
      countOfTemplatesInShake: CountOfTemplatesInShake.ONE,
    }),
}).default({
  grid: {
    spaceBetweenRows: 24,
    spaceBetweenProducts: 20,
  },
  module: {
    alignment: AlignmentHorizontalOption.LEFT,
    padding: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    spaceBetweenPhotoAndContent: 20,
    spaceBetweenTextElements: 8,
    background: { id: "color$$7", custom: "#000000" },
    border: {
      style: OutlineOptions.NONE,
      width: 10,
      color: { id: "color$$11", custom: "#000000" },
    },
    spaceBetweenSlides: 0,
  },
  pagination: GridPaginationOptions.DEFAULT,
  orderInModule: productElementInModule,
  marketingCampaign: {
    turnOn: false,
    positionOption: BannersPositions.SHAKE,
    fixPositionOrder: [],
    orderOption: BannersPositions.FIX,
    countOfTemplatesInShake: CountOfTemplatesInShake.ONE,
  },
}).catch({
  grid: {
    spaceBetweenRows: 24,
    spaceBetweenProducts: 20,
  },
  module: {
    alignment: AlignmentHorizontalOption.LEFT,
    padding: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    spaceBetweenPhotoAndContent: 20,
    spaceBetweenTextElements: 8,
    background: { id: "color$$7", custom: "#000000" },
    border: {
      style: OutlineOptions.NONE,
      width: 10,
      color: { id: "color$$11", custom: "#000000" },
    },
    spaceBetweenSlides: 0,
  },
  pagination: GridPaginationOptions.DEFAULT,
  orderInModule: productElementInModule,
  marketingCampaign: {
    turnOn: false,
    positionOption: BannersPositions.SHAKE,
    fixPositionOrder: [],
    orderOption: BannersPositions.FIX,
    countOfTemplatesInShake: CountOfTemplatesInShake.ONE,
  },
});

export const BoxSearchResultsContentScheme = z.object({
  showOnPageText: themeScheme.MessageScheme.default("Show on page"),
  sortingText: themeScheme.MessageScheme.default("Sort by:"),
  paginationText: themeScheme.MessageScheme.default("Pagination (to remove?)"),
  productButtonText: themeScheme.MessageScheme.default("Add to cart"),
  ratingText: themeScheme.MessageScheme.default("Reviews"),
});

export const BoxSrpProductSliderCustomAttributeScheme = z
  .object({
    prefix: z
      .object({
        heading: z.string().default("").catch(""),
        spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
      })
      .merge(
        themeScheme.createTextStyleShowSettingScheme(
          "typo$$1",
          "color$$1",
          false
        )
      ),
    suffix: z
      .object({
        heading: z.string().default("").catch(""),
        spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
      })
      .merge(
        themeScheme.createTextStyleShowSettingScheme(
          "typo$$1",
          "color$$1",
          false
        )
      ),
  })
  .merge(
    themeScheme.createTextStyleShowSettingScheme("typo$$1", "color$$1", false)
  );

export const BoxSearchResultsContentSettingsScheme = z.object({
  productsPerRow: z.number().default(3).catch(3),
  productsOnPage: z.number().default(36).catch(36),
  showOnPage: themeScheme
    .createTextStyleShowSettingScheme("typo$$7", "color$$1")
    .default(themeScheme.textStyleShowSettingFallback("typo$$7", "color$$1")),
  sorting: themeScheme
    .createTextStyleShowSettingScheme("typo$$7", "color$$1")
    .default(themeScheme.textStyleShowSettingFallback("typo$$7", "color$$1")),
  pagination: themeScheme
    .createTextStyleShowSettingScheme("typo$$7", "color$$2")
    .omit({
      show: true,
    })
    .default(themeScheme.textStyleShowSettingFallback("typo$$7", "color$$2")),
  product: z
    .object({
      sku: z
        .object({
          prefix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
          suffix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
        })
        .merge(
          themeScheme.createTextStyleShowSettingScheme("typo$$7", "color$$2")
        )
        .default({
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...themeScheme
            .createTextStyleShowSettingScheme("typo$$7", "color$$2")
            .parse({}),
        })
        .catch({
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...themeScheme
            .createTextStyleShowSettingScheme("typo$$7", "color$$2")
            .parse({}),
        }),
      productName: z
        .object({
          link: createLinkStyleSettingScheme("link$$1"),
          text: createTypoStyleSettingScheme("typo$$7"),
          show: z.boolean().default(true).catch(true),
          prefix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
          suffix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
        })
        .default({
          link: createLinkStyleSettingScheme("link$$1").parse({}),
          text: createTypoStyleSettingScheme("typo$$7").parse({}),
          show: true,
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
        })
        .catch({
          link: createLinkStyleSettingScheme("link$$1").parse({}),
          text: createTypoStyleSettingScheme("typo$$7").parse({}),
          show: true,
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
        }),
      actualPrice: z
        .object({
          prefix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
          suffix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
        })
        .merge(
          themeScheme.createTextStyleShowSettingScheme("typo$$4", "color$$1")
        )
        .default({
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...themeScheme
            .createTextStyleShowSettingScheme("typo$$4", "color$$1")
            .parse({}),
        })
        .catch({
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...themeScheme
            .createTextStyleShowSettingScheme("typo$$4", "color$$1")
            .parse({}),
        }),
      oldPrice: z
        .object({
          prefix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
          suffix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
        })
        .merge(
          themeScheme.createTextStyleShowSettingScheme("typo$$7", "color$$2")
        )
        .default({
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...themeScheme
            .createTextStyleShowSettingScheme("typo$$7", "color$$2")
            .parse({}),
        })
        .catch({
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...themeScheme
            .createTextStyleShowSettingScheme("typo$$7", "color$$2")
            .parse({}),
        }),
      netPrice: z
        .object({
          prefix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
          suffix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
        })
        .merge(
          themeScheme.createTextStyleShowSettingScheme("typo$$1", "color$$1")
        )
        .default({
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...themeScheme
            .createTextStyleShowSettingScheme("typo$$1", "color$$1")
            .parse({}),
        })
        .catch({
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...themeScheme
            .createTextStyleShowSettingScheme("typo$$1", "color$$1")
            .parse({}),
        }),
      rating: z
        .object({
          filledPointsColor: themeScheme.ColorReadoutValueScheme.removeCatch()
            .removeDefault()
            .default({ id: "color$$1", custom: "#000000" })
            .catch({ id: "color$$1", custom: "#000000" }),
          unfilledPointsColor: themeScheme.ColorReadoutValueScheme.removeCatch()
            .removeDefault()
            .default({ id: "color$$2", custom: "#000000" })
            .catch({ id: "color$$2", custom: "#000000" }),
          prefix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
          suffix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
        })
        .merge(
          themeScheme.createTextStyleShowSettingScheme("typo$$7", "color$$1")
        )
        .default({
          filledPointsColor: { id: "color$$1", custom: "#000000" },
          unfilledPointsColor: { id: "color$$2", custom: "#000000" },
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...themeScheme
            .createTextStyleShowSettingScheme("typo$$7", "color$$1")
            .parse({}),
        })
        .catch({
          filledPointsColor: { id: "color$$1", custom: "#000000" },
          unfilledPointsColor: { id: "color$$2", custom: "#000000" },
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...themeScheme
            .createTextStyleShowSettingScheme("typo$$7", "color$$1")
            .parse({}),
        }),
      delivery: z
        .object({
          prefix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
          suffix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
        })
        .merge(
          themeScheme.createTextStyleShowSettingScheme("typo$$7", "color$$2")
        )
        .default({
          prefix: {
            heading: "",
            spaceBetweenHeadingAndAttribute: 5,
            ...themeScheme
              .createTextStyleShowSettingScheme("typo$$1", "color$$1", false)
              .parse({}),
          },
          suffix: {
            heading: "",
            spaceBetweenHeadingAndAttribute: 5,
            ...themeScheme
              .createTextStyleShowSettingScheme("typo$$1", "color$$1", false)
              .parse({}),
          },
          ...themeScheme
            .createTextStyleShowSettingScheme("typo$$7", "color$$2")
            .parse({}),
        }),
      button: z
        .object({
          isCustomWidth: z.boolean().default(false).catch(false),
          customWidth: z.number().default(100).catch(100),
          type: z
            .nativeEnum(ProductButtonActions)
            .default(ProductButtonActions.ADD_TO_CART)
            .catch(ProductButtonActions.ADD_TO_CART),
          url: themeScheme.UrlScheme.default("").catch(""),
        })
        .merge(MainButtonContentSettingScheme),
      custom: z
        .record(z.string(), BoxSrpProductSliderCustomAttributeScheme)
        .default({})
        .catch({}),
    })
    .default({
      sku: themeScheme.productAffixFallback,
      actualPrice: themeScheme.productAffixFallback,
      oldPrice: themeScheme.productAffixFallback,
      netPrice: themeScheme.productAffixFallback,
      delivery: themeScheme.productAffixFallback,
      productName: {
        ...themeScheme.productAffixFallback,
        link: createLinkStyleSettingScheme("link$$1").parse({}),
        text: createTypoStyleSettingScheme("typo$$7").parse({}),
      },
      rating: {
        ...themeScheme.productAffixFallback,
        filledPointsColor: { id: "color$$1", custom: "#000000" },
        unfilledPointsColor: { id: "color$$2", custom: "#000000" },
      },
      custom: {},
      button: {
        isCustomWidth: false,
        customWidth: 100,
        type: ProductButtonActions.ADD_TO_CART,
        url: "",
        ...MainButtonContentSettingScheme.parse({}),
      },
    }),
  productPhoto: z
    .object({
      height: z.number().default(364).catch(364),
      hover: z
        .union([z.nativeEnum(HoverEffects), z.nativeEnum(ProductHoverEffects)])
        .default(HoverEffects.NONE)
        .catch(HoverEffects.NONE),
    })
    .default({ height: 364, hover: HoverEffects.NONE })
    .catch({
      height: 364,
      hover: HoverEffects.NONE,
    }),
  badge: z
    .object({
      show: z.boolean().default(true).catch(true),
    })
    .default({ show: true }),
  favorites: z
    .object({
      show: z.boolean().default(true).catch(true),
    })
    .default({ show: true }),
  variants: z.array(z.any()).default([]).catch([]),
});
