import { z } from "zod";
import { IPortalBox } from "@ecp-pageTypes";
import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import { PIMInputTypes } from "@ecp-redux/dto/productList.types";
import { SortingItemType } from "@ecp-redux/dto/searchResults.types";
import {
  IElementTextContent,
  IElementVisibility,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  ISpacing,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { SliderPaginationOptions } from "../../settingsPatterns/boxes/BoxSlider/StyledSlider.types";
import {
  IProductModuleElementsStyle,
  IProductModulePhoto,
  IProductModuleSettings,
} from "../../shared/components/domain/Product/ProductModule/ProductModule.types";
import { TVariant } from "../../shared/components/domain/Product/ProductVariants/ProductVariants.types";
import {
  IBoxProductSliderMessagesScheme,
  IBoxProductSliderSettingsScheme,
} from "./BoxProductSlider.scheme";

export type IBoxProductSliderData = GenericBoxData<
  IBoxProductSliderContent,
  IBoxProductSliderContentSettings,
  IBoxProductSliderDisplaySettings,
  IBoxProductSliderMessages,
  IBoxProductSliderSettings
>;

export type IBoxProductSliderMessages = z.infer<
  typeof IBoxProductSliderMessagesScheme
>;
export type IBoxProductSliderSettings = z.infer<
  typeof IBoxProductSliderSettingsScheme
>;

export type IBoxProductSliderProps = IPortalBox<IBoxProductSliderData> & {
  editMode: boolean;
};

export interface IBoxProductSliderContent {
  ratingText: string;
  productButtonText: string;
  headerText: string;
}

export enum SlideRotateOptions {
  SINGLE = "SINGLE",
  LIST = "LIST",
}

export enum dynamicProductListOption {
  WISH_LIST = "WISH_LIST",
  LAST_SEEN = "LAST_SEEN",
  PRODUCT_ASSOCIATION = "PRODUCT_ASSOCIATION",
  DYNAMIC_ASSOCIATION = "DYNAMIC_ASSOCIATION",
}

export enum ProductSliderBoxType {
  SLIDER = "SLIDER",
  GRID = "GRID",
}

export enum ProductSliderBoxViewType {
  TOP_PICTURE = "TOP_PICTURE",
  LEFT_PICTURE = "LEFT_PICTURE",
}

export interface IHEaderStyle extends IElementTextContent {
  padding: ISpacing;
  contentAlignment: AlignmentHorizontalOption;
}

export type ProductForSliderModuleElement =
  | "productName"
  | "actualPrice"
  | "oldPrice"
  | "netPrice"
  | "rating"
  | "button"
  | "custom";

interface IProductSliderPhoto extends IProductModulePhoto {
  photoSize: number;
}

export interface IBoxProductSliderContentSettings {
  sliderSettings: {
    type: ProductSliderBoxType;
    viewType: ProductSliderBoxViewType;
    maxNumberOfProducts: number;
    maxNumberOfProductsGrid: number;
    numberOfProductsPerSlide: number;
    numberOfProductsPerRow: number;
    numberOfRows: number;
    sorting: SortingItemType;
    sortingAttributeName: string;
    sortingAttributeType: PIMInputTypes | "";
    sortingAttributeCode: string;
    sortingAttributeVariant: string;
  };
  badge: IElementVisibility;
  favorites: IElementVisibility;
  productPhoto: IProductSliderPhoto;
  product: Pick<IProductModuleElementsStyle, ProductForSliderModuleElement>;
  searchQuery: string;
  dynamicProductListOption?: dynamicProductListOption | null;
  selectedAssociation?: string | null;
  header: IHEaderStyle;
  variants: TVariant[];
  attributes: string[];
}

export type TPageStyle = {
  spaceBetweenRows: number;
  spaceBetweenProducts: number;
};

export interface IProductSliderSettings extends IProductModuleSettings {
  attributesAlignment: AlignmentVerticalOption;
  pictureAlignment: AlignmentVerticalOption;
  spaceBetweenPhotoAndAttributes: number;
}

export interface IBoxProductSliderDisplaySettings {
  paginationStyle: SliderPaginationOptions;
  paginationStyleGrid: Omit<
    SliderPaginationOptions,
    SliderPaginationOptions.ARROWS
  >;
  slidesRotate: number[];
  slideRotateType: SlideRotateOptions;
  pageStyle: TPageStyle;
  module: IProductSliderSettings;
  orderInModule: (ProductForSliderModuleElement | string)[];
}

export type TProductsDataObject = {
  products: string[] | undefined;
  size: number;
};
