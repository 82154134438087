import { z } from "zod";
import {
  AccordionIdScheme,
  Border_v2Scheme,
  ButtonIdScheme,
  ColorReadoutValueScheme,
  GlobalAccordionObject,
  GlobalColorsStylesSchema,
  InputIdScheme,
  LinkIdScheme,
  TypoIdScheme,
  TypoReadoutValueScheme,
  UrlScheme,
  oldBorderSchema,
} from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  FontFamilyOptions,
  FontWeightOptionsDeprecated,
  FontWeightOptionsLongListOfWeights,
  OutlineOptions,
  SettingPositionOptions,
  TAccordionId,
  TColorId,
  TInputId,
  TLinkId,
  TTypoId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { ImageInlineWidthOptions } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { getDefaultsFromZod } from "../helpers/helpers";

export const createImageContentSettingScheme = (imageUrl?: string) => {
  return z.object({
    show: z.boolean().default(true).catch(true),
    alignment: z
      .object({
        vertical: z.nativeEnum(AlignmentVerticalOption),
        horizontal: z.nativeEnum(AlignmentHorizontalOption),
      })
      .default({
        vertical: AlignmentVerticalOption.CENTER,
        horizontal: AlignmentHorizontalOption.LEFT,
      }),
    backgroundUrl: z
      .string()
      .default(imageUrl ?? "")
      .catch(imageUrl ?? ""),
    opacity: z.number().default(100).catch(100),
    fit: z.boolean().default(false).catch(false),
    parallax: z.boolean().default(false).catch(false),
  });
};

export const InnerBoxContentSettingScheme = z.object({
  show: z.boolean().default(true).catch(true),
  color: ColorReadoutValueScheme.default({
    id: "color$$3",
    custom: "#000000",
  }).catch({
    id: "color$$3",
    custom: "#000000",
  }),
  alignment: z
    .object({
      horizontal: z.nativeEnum(AlignmentHorizontalOption),
      vertical: z.nativeEnum(AlignmentVerticalOption),
    })
    .default({
      horizontal: AlignmentHorizontalOption.LEFT,
      vertical: AlignmentVerticalOption.BOTTOM,
    }),
  contentAlignment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  textPadding: z
    .object({
      top: z.number(),
      right: z.number(),
      bottom: z.number(),
      left: z.number(),
    })
    .default({ top: 2, right: 2, bottom: 2, left: 2 })
    .catch({ top: 2, right: 2, bottom: 2, left: 2 }),
  width: z.number().default(40).catch(40),
});

export const createTitleContentSettingsScheme = (
  typoId?: TTypoId,
  colorId?: TColorId
) =>
  z.object({
    show: z.boolean().default(true).catch(true),
    font: TypoReadoutValueScheme.default({
      id: typoId ?? "typo$$1",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 2,
        lineHeight: 2,
        letterSpacing: 2,
      },
    }).catch({
      id: typoId ?? "typo$$1",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 2,
        lineHeight: 2,
        letterSpacing: 2,
      },
    }),
    text: z
      .object({
        color: ColorReadoutValueScheme.default({
          id: colorId ?? "color$$1",
          custom: "#000000",
        }).catch({ id: colorId ?? "color$$1", custom: "#000000" }),
      })
      .default({ color: { id: colorId ?? "color$$1", custom: "#000000" } })
      .catch({
        color: { id: colorId ?? "color$$1", custom: "#000000" },
      }),
  });

export const createShowMoreTextStyleShowSettingScheme = (
  typoId?: TTypoId,
  colorId?: TColorId,
  initialLimit?: number,
  show?: boolean
) =>
  z.object({
    show: z
      .boolean()
      .default(show ?? true)
      .catch(show ?? true),
    font: TypoReadoutValueScheme.default({
      id: typoId ?? "typo$$2",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 2,
        lineHeight: 2,
        letterSpacing: 2,
      },
    }).catch({
      id: typoId ?? "typo$$2",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 2,
        lineHeight: 2,
        letterSpacing: 2,
      },
    }),
    text: z
      .object({
        color: ColorReadoutValueScheme,
      })
      .default({
        color: { id: colorId ?? "color$$2", custom: "#000000" },
      })
      .catch({ color: { id: colorId ?? "color$$2", custom: "#000000" } }),
    initialLimit: z
      .number()
      .default(initialLimit ?? 4)
      .catch(initialLimit ?? 4),
  });

export const MainButtonContentSettingScheme = z.object({
  id: ButtonIdScheme.or(z.literal("custom"))
    .default("button$$1")
    .catch("button$$1"),
  show: z.boolean().default(true).catch(true),
  custom: z
    .object({
      horizontalPadding: z.number().default(35).catch(35),
      verticalPadding: z.number().default(8).catch(8),
      background: GlobalColorsStylesSchema.removeDefault()
        .default({
          color: { id: "color$$2", custom: "#000000" },
          hoverColor: { id: "color$$2", custom: "#000000" },
          activeColor: { id: "color$$3", custom: "#000000" },
          disabledColor: { id: "color$$4", custom: "#000000" },
        })
        .catch({
          color: { id: "color$$2", custom: "#000000" },
          hoverColor: { id: "color$$2", custom: "#000000" },
          activeColor: { id: "color$$3", custom: "#000000" },
          disabledColor: { id: "color$$4", custom: "#000000" },
        }),
      border: oldBorderSchema
        .merge(GlobalColorsStylesSchema.removeDefault())
        .default({
          radius: 4,
          width: 0,
          radiusV2: null,
          widthV2: null,
          color: { id: "color$$5", custom: "#000000" },
          hoverColor: { id: "color$$6", custom: "#000000" },
          activeColor: { id: "color$$7", custom: "#000000" },
          disabledColor: { id: "color$$8", custom: "#000000" },
        })
        .catch({
          radius: 4,
          width: 0,
          radiusV2: null,
          widthV2: null,
          color: { id: "color$$5", custom: "#000000" },
          hoverColor: { id: "color$$6", custom: "#000000" },
          activeColor: { id: "color$$7", custom: "#000000" },
          disabledColor: { id: "color$$8", custom: "#000000" },
        }),
      text: GlobalColorsStylesSchema.removeDefault()
        .default({
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$10", custom: "#000000" },
          activeColor: { id: "color$$11", custom: "#000000" },
          disabledColor: { id: "color$$12", custom: "#000000" },
        })
        .catch({
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$10", custom: "#000000" },
          activeColor: { id: "color$$11", custom: "#000000" },
          disabledColor: { id: "color$$12", custom: "#000000" },
        }),
      font: TypoReadoutValueScheme.default({
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      }).catch({
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      }),
      icon: z
        .object({
          iconUrl: UrlScheme.nullable(),
          position: z.nativeEnum(SettingPositionOptions),
          size: z.number(),
          spacing: z.number(),
          color: ColorReadoutValueScheme,
          hoverColor: ColorReadoutValueScheme,
        })
        .default({
          iconUrl: null,
          position: SettingPositionOptions.RIGHT,
          size: 16,
          spacing: 10,
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$1", custom: "#000000" },
        })
        .catch({
          iconUrl: null,
          position: SettingPositionOptions.RIGHT,
          size: 16,
          spacing: 10,
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$1", custom: "#000000" },
        }),
    })
    .default({
      horizontalPadding: 35,
      verticalPadding: 8,
      background: {
        color: { id: "color$$2", custom: "#000000" },
        hoverColor: { id: "color$$2", custom: "#000000" },
        activeColor: { id: "color$$3", custom: "#000000" },
        disabledColor: { id: "color$$4", custom: "#000000" },
      },
      border: {
        radius: 4,
        width: 0,
        radiusV2: null,
        widthV2: null,
        color: { id: "color$$5", custom: "#000000" },
        hoverColor: { id: "color$$6", custom: "#000000" },
        activeColor: { id: "color$$7", custom: "#000000" },
        disabledColor: { id: "color$$8", custom: "#000000" },
      },
      text: {
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$10", custom: "#000000" },
        activeColor: { id: "color$$11", custom: "#000000" },
        disabledColor: { id: "color$$12", custom: "#000000" },
      },
      font: {
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      icon: {
        iconUrl: null,
        position: SettingPositionOptions.RIGHT,
        size: 16,
        spacing: 10,
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
      },
    })
    .catch({
      horizontalPadding: 35,
      verticalPadding: 8,
      background: {
        color: { id: "color$$2", custom: "#000000" },
        hoverColor: { id: "color$$2", custom: "#000000" },
        activeColor: { id: "color$$3", custom: "#000000" },
        disabledColor: { id: "color$$4", custom: "#000000" },
      },
      border: {
        radius: 4,
        width: 0,
        radiusV2: null,
        widthV2: null,
        color: { id: "color$$5", custom: "#000000" },
        hoverColor: { id: "color$$6", custom: "#000000" },
        activeColor: { id: "color$$7", custom: "#000000" },
        disabledColor: { id: "color$$8", custom: "#000000" },
      },
      text: {
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$10", custom: "#000000" },
        activeColor: { id: "color$$11", custom: "#000000" },
        disabledColor: { id: "color$$12", custom: "#000000" },
      },
      font: {
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      icon: {
        iconUrl: null,
        position: SettingPositionOptions.RIGHT,
        size: 16,
        spacing: 10,
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
      },
    }),
});

export const SecondButtonContentSettingScheme = z.object({
  id: ButtonIdScheme.or(z.literal("custom"))
    .default("button$$1")
    .catch("button$$1"),
  show: z.boolean().default(true).catch(true),
  custom: z
    .object({
      horizontalPadding: z.number().default(35).catch(35),
      verticalPadding: z.number().default(8).catch(8),
      background: GlobalColorsStylesSchema.removeDefault()
        .default({
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$1", custom: "#000000" },
          activeColor: { id: "color$$1", custom: "#000000" },
          disabledColor: { id: "color$$1", custom: "#000000" },
        })
        .catch({
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$1", custom: "#000000" },
          activeColor: { id: "color$$1", custom: "#000000" },
          disabledColor: { id: "color$$1", custom: "#000000" },
        }),
      border: oldBorderSchema
        .merge(GlobalColorsStylesSchema.removeDefault())
        .default({
          radius: 4,
          width: 0,
          radiusV2: null,
          widthV2: null,
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$1", custom: "#000000" },
          activeColor: { id: "color$$1", custom: "#000000" },
          disabledColor: { id: "color$$1", custom: "#000000" },
        })
        .catch({
          radius: 4,
          width: 0,
          radiusV2: null,
          widthV2: null,
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$1", custom: "#000000" },
          activeColor: { id: "color$$1", custom: "#000000" },
          disabledColor: { id: "color$$1", custom: "#000000" },
        }),
      text: GlobalColorsStylesSchema.removeDefault()
        .default({
          color: { id: "color$$2", custom: "#000000" },
          hoverColor: { id: "color$$2", custom: "#000000" },
          activeColor: { id: "color$$2", custom: "#000000" },
          disabledColor: { id: "color$$2", custom: "#000000" },
        })
        .catch({
          color: { id: "color$$2", custom: "#000000" },
          hoverColor: { id: "color$$2", custom: "#000000" },
          activeColor: { id: "color$$2", custom: "#000000" },
          disabledColor: { id: "color$$2", custom: "#000000" },
        }),
      font: TypoReadoutValueScheme.default({
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      }).catch({
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      }),
      icon: z
        .object({
          iconUrl: UrlScheme.nullable(),
          position: z.nativeEnum(SettingPositionOptions),
          size: z.number(),
          spacing: z.number(),
          color: ColorReadoutValueScheme,
          hoverColor: ColorReadoutValueScheme,
        })
        .default({
          iconUrl: null,
          position: SettingPositionOptions.RIGHT,
          size: 16,
          spacing: 10,
          color: ColorReadoutValueScheme.parse({
            id: "color$$1",
            custom: "#000000",
          }),
          hoverColor: ColorReadoutValueScheme.parse({
            id: "color$$1",
            custom: "#000000",
          }),
        })
        .catch({
          iconUrl: null,
          position: SettingPositionOptions.RIGHT,
          size: 16,
          spacing: 10,
          color: ColorReadoutValueScheme.parse({
            id: "color$$1",
            custom: "#000000",
          }),
          hoverColor: ColorReadoutValueScheme.parse({
            id: "color$$1",
            custom: "#000000",
          }),
        }),
    })
    .default({
      horizontalPadding: 35,
      verticalPadding: 8,
      background: {
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
        activeColor: { id: "color$$1", custom: "#000000" },
        disabledColor: { id: "color$$1", custom: "#000000" },
      },
      border: {
        radiusV2: null,
        widthV2: null,
        width: 0,
        radius: 4,
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
        activeColor: { id: "color$$1", custom: "#000000" },
        disabledColor: { id: "color$$1", custom: "#000000" },
      },
      text: {
        color: { id: "color$$2", custom: "#000000" },
        hoverColor: { id: "color$$2", custom: "#000000" },
        activeColor: { id: "color$$2", custom: "#000000" },
        disabledColor: { id: "color$$2", custom: "#000000" },
      },
      font: {
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      icon: {
        iconUrl: null,
        position: SettingPositionOptions.RIGHT,
        size: 16,
        spacing: 10,
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
      },
    })
    .catch({
      horizontalPadding: 35,
      verticalPadding: 8,
      background: {
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
        activeColor: { id: "color$$1", custom: "#000000" },
        disabledColor: { id: "color$$1", custom: "#000000" },
      },
      border: {
        radiusV2: null,
        widthV2: null,
        width: 0,
        radius: 4,
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
        activeColor: { id: "color$$1", custom: "#000000" },
        disabledColor: { id: "color$$1", custom: "#000000" },
      },
      text: {
        color: { id: "color$$2", custom: "#000000" },
        hoverColor: { id: "color$$2", custom: "#000000" },
        activeColor: { id: "color$$2", custom: "#000000" },
        disabledColor: { id: "color$$2", custom: "#000000" },
      },
      font: {
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      icon: {
        iconUrl: null,
        position: SettingPositionOptions.RIGHT,
        size: 16,
        spacing: 10,
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
      },
    }),
});

export const createLinkStyleSettingScheme = (linkId: TLinkId | "custom") =>
  z
    .object({
      id: LinkIdScheme.or(z.literal("custom"))
        .default(linkId ?? "link$$1")
        .catch(linkId ?? "link$$1"),
      custom: z
        .object({
          text: GlobalColorsStylesSchema.removeDefault()
            .omit({ disabledColor: true })
            .default({
              color: { id: "color$$1", custom: "#000000" },
              hoverColor: { id: "color$$1", custom: "#000000" },
              activeColor: { id: "color$$1", custom: "#000000" },
            })
            .catch({
              color: { id: "color$$1", custom: "#000000" },
              hoverColor: { id: "color$$1", custom: "#000000" },
              activeColor: { id: "color$$1", custom: "#000000" },
            }),
        })
        .default({
          text: {
            color: { id: "color$$1", custom: "#000000" },
            hoverColor: { id: "color$$1", custom: "#000000" },
            activeColor: { id: "color$$1", custom: "#000000" },
          },
        }),
    })
    .default({
      id: linkId ?? "link$$1",
      custom: {
        text: {
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$1", custom: "#000000" },
          activeColor: { id: "color$$1", custom: "#000000" },
        },
      },
    });

export const createTypoStyleSettingScheme = (typoId?: TTypoId) =>
  z.object({
    id: TypoIdScheme.or(z.literal("custom"))
      .default(typoId ?? "typo$$1")
      .catch(typoId ?? "typo$$1"),
    custom: z
      .object({
        family: z
          .nativeEnum(FontFamilyOptions)
          .default(FontFamilyOptions.ROBOTO)
          .catch(FontFamilyOptions.ROBOTO),
        weight: z
          .union([
            z.nativeEnum(FontWeightOptionsLongListOfWeights),
            z.nativeEnum(FontWeightOptionsDeprecated),
          ])
          .default(FontWeightOptionsLongListOfWeights.Weight300)
          .catch(FontWeightOptionsLongListOfWeights.Weight300),
        size: z.number().default(24).catch(24),
        lineHeight: z.number().default(10).catch(10),
        letterSpacing: z.number().default(1).catch(1),
      })
      .default({
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 24,
        lineHeight: 10,
        letterSpacing: 1,
      })
      .catch({
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 24,
        lineHeight: 10,
        letterSpacing: 1,
      }),
  });

export const createInputStyleSettingScheme = (inputId: TInputId | "custom") =>
  z.object({
    show: z.boolean().default(true).catch(true),
    id: InputIdScheme.or(z.literal("custom"))
      .default(inputId ?? "input$$1")
      .catch(inputId ?? "input$$1"),
    custom: z
      .object({
        width: z.number().default(203).catch(203),
        height: z.number().default(37).catch(37),
        border: Border_v2Scheme.default({
          style: OutlineOptions.SOLID,
          radius: 0,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          color: { id: "color$$1", custom: "#000000" },
        }).catch({
          style: OutlineOptions.SOLID,
          radius: 0,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          color: { id: "color$$1", custom: "#000000" },
        }),
        activeBorder: Border_v2Scheme.default({
          style: OutlineOptions.SOLID,
          radius: 0,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          color: { id: "color$$1", custom: "#000000" },
        }).catch({
          style: OutlineOptions.SOLID,
          radius: 0,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          color: { id: "color$$1", custom: "#000000" },
        }),
        hoverBorder: Border_v2Scheme.default({
          style: OutlineOptions.SOLID,
          radius: 0,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          color: { id: "color$$1", custom: "#000000" },
        }).catch({
          style: OutlineOptions.SOLID,
          radius: 0,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          color: { id: "color$$1", custom: "#000000" },
        }),
        disabledBorder: Border_v2Scheme.default({
          style: OutlineOptions.SOLID,
          radius: 0,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          color: { id: "color$$1", custom: "#000000" },
        }).catch({
          style: OutlineOptions.SOLID,
          radius: 0,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          color: { id: "color$$1", custom: "#000000" },
        }),
        errorBorder: Border_v2Scheme.default({
          style: OutlineOptions.SOLID,
          radius: 0,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          color: { id: "color$$1", custom: "#000000" },
        }).catch({
          style: OutlineOptions.SOLID,
          radius: 0,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          color: { id: "color$$1", custom: "#000000" },
        }),
        background: GlobalColorsStylesSchema.default({
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$1", custom: "#000000" },
          activeColor: { id: "color$$1", custom: "#000000" },
          disabledColor: { id: "color$$1", custom: "#000000" },
        }).catch({
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$1", custom: "#000000" },
          activeColor: { id: "color$$1", custom: "#000000" },
          disabledColor: { id: "color$$1", custom: "#000000" },
        }),
        font: z
          .object({
            input: createTypoStyleSettingScheme("typo$$1"),
            label: createTypoStyleSettingScheme("typo$$1"),
            placeholder: createTypoStyleSettingScheme("typo$$1"),
            error: createTypoStyleSettingScheme("typo$$1"),
          })
          .default({
            input: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            label: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            placeholder: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            error: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
          })
          .catch({
            input: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            label: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            placeholder: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            error: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
          }),
        text: z
          .object({
            errorColor: ColorReadoutValueScheme.catch({
              id: "color$$1",
              custom: "#000000",
            }),
            placeholderColor: ColorReadoutValueScheme.catch({
              id: "color$$1",
              custom: "#000000",
            }),
            labelColor: ColorReadoutValueScheme.catch({
              id: "color$$1",
              custom: "#000000",
            }),
            color: ColorReadoutValueScheme.catch({
              id: "color$$1",
              custom: "#000000",
            }),
            hoverColor: ColorReadoutValueScheme.catch({
              id: "color$$1",
              custom: "#000000",
            }),
            activeColor: ColorReadoutValueScheme.catch({
              id: "color$$1",
              custom: "#000000",
            }),
            disabledColor: ColorReadoutValueScheme.catch({
              id: "color$$1",
              custom: "#000000",
            }),
          })
          .default({
            errorColor: { id: "color$$1", custom: "#000000" },
            placeholderColor: { id: "color$$1", custom: "#000000" },
            labelColor: { id: "color$$1", custom: "#000000" },
            color: { id: "color$$1", custom: "#000000" },
            hoverColor: { id: "color$$1", custom: "#000000" },
            activeColor: { id: "color$$1", custom: "#000000" },
            disabledColor: { id: "color$$1", custom: "#000000" },
          })
          .catch({
            errorColor: { id: "color$$1", custom: "#000000" },
            placeholderColor: { id: "color$$1", custom: "#000000" },
            labelColor: { id: "color$$1", custom: "#000000" },
            color: { id: "color$$1", custom: "#000000" },
            hoverColor: { id: "color$$1", custom: "#000000" },
            activeColor: { id: "color$$1", custom: "#000000" },
            disabledColor: { id: "color$$1", custom: "#000000" },
          }),
      })
      .default({
        width: 203,
        height: 37,
        border: {
          style: OutlineOptions.SOLID,
          radius: 0,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          color: { id: "color$$1", custom: "#000000" },
        },
        activeBorder: {
          style: OutlineOptions.SOLID,
          radius: 0,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          color: { id: "color$$1", custom: "#000000" },
        },
        hoverBorder: {
          style: OutlineOptions.SOLID,
          radius: 0,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          color: { id: "color$$1", custom: "#000000" },
        },
        disabledBorder: {
          style: OutlineOptions.SOLID,
          radius: 0,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          color: { id: "color$$1", custom: "#000000" },
        },
        errorBorder: {
          style: OutlineOptions.SOLID,
          radius: 0,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          color: { id: "color$$1", custom: "#000000" },
        },
        background: {
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$1", custom: "#000000" },
          activeColor: { id: "color$$1", custom: "#000000" },
          disabledColor: { id: "color$$1", custom: "#000000" },
        },
        font: {
          input: createTypoStyleSettingScheme("typo$$1"),
          label: createTypoStyleSettingScheme("typo$$1"),
          placeholder: createTypoStyleSettingScheme("typo$$1"),
          error: createTypoStyleSettingScheme("typo$$1"),
        },
        text: {
          errorColor: { id: "color$$1", custom: "#000000" },
          placeholderColor: { id: "color$$1", custom: "#000000" },
          labelColor: { id: "color$$1", custom: "#000000" },
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$1", custom: "#000000" },
          activeColor: { id: "color$$1", custom: "#000000" },
          disabledColor: { id: "color$$1", custom: "#000000" },
        },
      }),
  });

export const createAccordionStyleSettingScheme = (
  accordionId: TAccordionId | "custom"
) =>
  z
    .object({
      id: AccordionIdScheme.or(z.literal("custom"))
        .default(accordionId ?? "accordion$$1")
        .catch(accordionId ?? "accordion$$1"),
      custom: GlobalAccordionObject.default(
        getDefaultsFromZod(GlobalAccordionObject)
      ),
    })
    .default({
      id: accordionId ?? "accordion$$1",
      custom: getDefaultsFromZod(GlobalAccordionObject),
    });

export const ImageInlineContentSettingsScheme = z.object({
  show: z.boolean().default(true).catch(true),
  opacity: z.number().default(20).catch(20),
  width: z
    .nativeEnum(ImageInlineWidthOptions)
    .default(ImageInlineWidthOptions.ONE_THIRD)
    .catch(ImageInlineWidthOptions.ONE_THIRD),
});
